/* styles.css */
.loading-dots {
  display: flex;
  /* justify-content: center; */
  align-items: baseline;
}

.dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #000;
  margin: 0 5px;
  animation: bounce 1s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-6px);
  }
}