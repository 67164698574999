.datePicker {
  margin-right: 50px !important;
}

.timePicker {
  margin-right: 50px !important;
}

.datePickerContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
}

.fam-app .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 1.3em;
}

.fam-app .MuiInputBase-input {
  font-size: 1.4em;
}

.fam-app .MuiTypography-body1 {
  font-size: 1.1em;
}

.fam-app legend {
  font-size: 1.4em;
}

.GenerateEmailButton {
  text-align: center;
}
.feedbackLink {
  text-transform: capitalize;
  border: none;
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  background-color: #383838;
  color: white;
  font-weight: bold;
  padding-bottom: inherit !important;
}

.pageHeader, .pageHeaderFeedback, .pageHeaderCdsid  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #383838;
  font-size: 1.2em;
  color: white;
  font-weight: bold;
  padding-bottom: inherit !important;
}

.feedbackLink {
  cursor: pointer;
  -o-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
}
.feedbackLink:hover{
  cursor:pointer;
  color:#3f51b5;
}

.pageLabel {
  background-color: #757575;
  font-size: 1.75em;
  color: white;
  font-weight: bold;
  padding-bottom: inherit !important;
  padding-top: inherit !important;
}

.statusPriorityContainer {
  padding-left: initial !important;
}
.modalHeader{
  background-color: #383838;
  color: white;
}

.modalContent{
  background-color: #f2f2f2;
}
.MuiTypography-colorTextSecondary {
  color: black !important;
}
.sectionHeaders {
  padding: 15px 25px;
  font-size: 1.5em;
  background-color: #f2f2f2;
}

.updateHeader {
  margin-top: 0;
}

.darkSectionHeader {
  padding: 15px 25px;
  font-size: 1.5em;
  background-color: #757575;
  color: white;
}

.fam-app .darkSummarySection {
  padding: 15px 25px;
  font-size: 1.5em;
  font-weight: bold;
  background-color: #757575;
  color: white;
  width: 100%;
}

.lightGray {
  color: #bebebe;
}
.warningMessage {
  color: red;
}

.updateInformation {
  color: #bebebe;
  margin-bottom: 30px !important;
}

.fam-app h3 {
  font-size: 1.4em;
}

.updatedContent .MuiInputBase-root.MuiInput-root.MuiInput-underline {
  padding: 27px 12px 10px;
  color: #7c7c7c;
  background-color: #f2f2f2;
}

.fam-app .MuiExpansionPanel-root:before {
  height: 0;
}

.fam-app .MuiExpansionPanel-root {
  width: 100%;
}

.fam-app .MuiExpansionPanelSummary-root {
  padding: 0 24px 0 10px;
}

.expandIcon {
  color: white;
}

.bodyText {
  font-size: 1.4em;
}

.regionsList .MuiListItem-root {
  padding-top: 0;
  padding-bottom: 0;
}

#regionsDialog h2 {
  font-size: 1.5em;
  font-weight: bold;
}

#regionsDialog {
  padding: 16px 24px 0 32px;
}

.regionsList.MuiList-padding {
  padding: 8px 16px 16px 16px;
}

.infoButton.MuiIconButton-root {
  padding-top: 0;
  padding-left: 4px;
}

.infoButton.MuiIconButton-root:hover {
  background-color: transparent;
}

.bulkmailLabel {
  color: #7c7c7c;
  font-size: 1em;
}

.bulkmailBodyText {
  font-size: 1.4em;
  margin-top: 8px;
  word-wrap: break-word;
}

.updatedContent .MuiInputLabel-shrink {
  transform: translate(0, -1px) scale(0.75) !important;
}

.noSelection {
  color: rgba(0, 0, 0, 0.50) !important;
}

.createAdvisoryButton.MuiIconButton-root:hover {
  background-color: transparent;
}

.createAdvisoryButton {
  font-size: 20px !important;
}

.createAdvisoryGrid {
  height: 35px;
}

.createAdvisoryIcon {
  padding-right: 10px;
}

.exportAdvisoryCSVIcon {
  padding-right: 10px;
  padding-left: 0;
}

.exportAdvisoryCSVButton.MuiIconButton-root:hover {
  background-color: transparent;
}

.exportAdvisoryCSVButton {
  font-size: 14px !important;
  padding-left: 0 !important;
}

.emailErrorMessage {
  color: red;
}

.invalidHTMLErrorMessage {
  color: red;
}

#search {
  font-size: 14px;
}

#search-helper-text {
  color:rgba(255, 0, 0, 0.75) !important;
}

.banner {
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #e53f1f;
  font-size: 1.3em;
  color: white;
  font-weight: bold;
  line-height: 2px;
  padding-bottom: inherit !important;
}

.banner.info {
  background-color: blue;
  font-size: 1em;
  line-height: 1.2em;
  font-weight: normal;
}

.csvLink {
  text-decoration: none;
}

.sendBulkmails {
  padding-bottom: 15px !important;
}

.pageContainer {
  padding-left: 5px !important;
}

.loadingAdvisoriesText {
  color: #3f51b5;
}

.loadingAdvisories {
  display: grid;
  justify-content: center;
  width: inherit;
  padding-top: 30px;
}