.feedback-text{
    text-align: center;
    padding: 2% 2% 0%;
}
.submitButtonDiv{
    text-align: center;
    padding: 2% 2% 0%;
}
.submitButton{
    color: #fff;
    background-color: #3f51b5;
    padding: 6px 16px;
    font-size: 0.875rem;
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
}
label {
    font-size: 26px;
}
.feedbackInput{
    padding: 2%;
}
.feedback-Form{
    padding-top: 2%;
}
.element{
    padding: 2%;
}
.element textarea {
    width: 384px;
    height: 24px;
}
.feedbackInput textarea {
    width: 50vw;
    height: 10vh;
}
.starTextGreatness,.starTextLikeliness, .starTextClarity, .starTextInformativity, .starTextConciseness {
    padding: 2% 0 0 29%;
}

.starTextGreatness span:first-of-type:after{
    content: "Not Great";
    font-size: 12px;
    padding-left: 22%;
    display: flex;
}
.starTextLikeliness span:first-of-type:after{
    content: "Not Likely";
    font-size: 12px;
    padding-left: 22%;
    display: flex;
}
.starTextClarity span:first-of-type:after{
    content: "Not Clear";
    font-size: 12px;
    padding-left: 24%;
    display: flex;
}
.starTextConciseness span:first-of-type:after{
    content: "Not Concise";
    font-size: 12px;
    padding-left: 14%;
    display: flex;
}
.starTextInformativity span:first-of-type:after{
    content: "Not Informative";
    font-size: 12px;
    padding-left: 6%;
    display: flex;
}
.starTextGreatness span:last-of-type:after{
    content: "Great";
    font-size: 12px;
    padding-left: 34%;
    display: flex;
}
.starTextLikeliness span:last-of-type:after{
    content: "Very Likely";
    font-size: 12px;
    display: flex;
    padding-left: 20%;
}
.starTextConciseness span:last-of-type:after{
    content: "Very Concise";
    padding-left: 14%;
    font-size: 12px;
    display: flex;
}
.starTextClarity span:last-of-type:after{
    content: "Very Clear";
    padding-left: 22%;
    font-size: 12px;
    display: flex;
}
.starTextInformativity span:last-of-type:after{
    content: "Very Informative";
    font-size: 12px;
    padding-left: 6%;
    display: flex;
}