.mainContent {
    padding-left: 3%;
    font-size: large;
}
.communityChannelTag{
    font-weight: bold;
}
.headerContent{
    background-color: #383838;
    color: #fff;
    padding: 3% 3% 3% 4%;;
    margin:0;
}
.exclamation{
    color: red;
    font-size: larger;
}
.checkMark{
    color: green;
    font-size: larger;
}

